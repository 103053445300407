"use client";

import React from "react";
import { z } from "zod";
import { Box, Typography } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FadeUp } from "@/src/shared/components/FadeUp";

const CourseSearchSchema = z.object({
  course: z.string().min(2, "course is required"),
});

export default function HomePageHero() {
  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<z.infer<typeof CourseSearchSchema>>({
    resolver: zodResolver(CourseSearchSchema),
    defaultValues: {
      course: "",
    },
  });
  const handleCourseChange = (event, newValue) => {
    setValue("course", newValue?.id, { shouldValidate: true });
  };

  const onSubmit: SubmitHandler<z.infer<typeof CourseSearchSchema>> = async (
    data
  ) => {
    try {
      const response = await fetch(`/api/courses/page/${data.course}`, {
        method: "GET",
      });
      const result = await response.json();
      if (result && result?.slug) {
        window.open(`/${result?.slug}`);
      }
    } catch (err) {}
  };

  return (
    <Box
      className="index-header text-center"
      sx={{
        position: "relative",
        height: {
          xs: "45vh",
          md: "80vh",
        },
        display: "flex",

        overflow: "hidden",
        width: "100%",
        backgroundImage: {
          xs: "linear-gradient(135deg, #051937, #004d7a, #008793)",
          md: "url(/images/homehero.webp)",
        },
        backgroundSize: "cover",
        backgroundPosition: "center",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          width: "80%", // Cover the entire width
          height: "100%",
          background:
            "linear-gradient(to left, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 80%)", // Gradient from left to right
        },
      }}
    >
      <Box alignItems="center" p={4} display="flex" width="100%" height="100%">
        <Box display={{ xs: "none", md: "block", lg: "block" }} flex={1} />
        <Box flex={2}>
          <Box height="100%" width="100%">
            <Box px={{ xs: 0, md: 4 }} height="100%" color="#fff">
              <FadeUp delay={0.25} duration={1}>
                <Typography
                  variant="h3"
                  gutterBottom
                  sx={{
                    fontSize: { xs: "2rem", md: "3.3rem" },
                  }}
                >
                  Learn Skills that Hires you.
                </Typography>
                <Typography variant="h1"> 100% Success Guaranteed!</Typography>
              </FadeUp>
              <FadeUp delay={0.5} duration={1}>
                <Typography
                  variant="h5"
                  sx={{ py: 2, fontSize: { xs: "1.2rem", md: "1.3rem" } }}
                >
                  Unlock Your Future with with In-Demand Skills. Experience
                  Hands-On Training, Industry-Aligned Curriculum, and 100%
                  Placement Support. At Brain Mentors, your success is our
                  commitment.
                </Typography>
              </FadeUp>
              <FadeUp delay={1} duration={1}>
                <Typography variant="body2" fontWeight="bold">
                  Amit Shrivastav
                </Typography>
                <Typography variant="caption" component="div">
                  Founder of Brain Mentors & Skillrisers
                </Typography>
              </FadeUp>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
