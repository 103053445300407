const isProduction = process.env.NODE_ENV === "production";
const baseURL = process.env.NEXT_PUBLIC_BASE_URL;
const dbUrl = process.env.DATABASE_URL;

const appConfig = {
  googleAnalyticsId: process.env.NEXT_PUBLIC_ANALYTICS_ID,
  googleTagManagerId: process.env.NEXT_PUBLIC_GTAG_ID,
  googleTagSendTo: process.env.NEXT_PUBLIC_SEND_TO,
  youTubeAPI: process.env.NEXT_PUBLIC_YOUTUBE_API,
  recaptchaSiteKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
  recaptchaSecretKey: process.env.RECAPTCHA_SECRET_KEY,
  mailFrom: process.env.NEXT_PUBLIC_MAIL_FROM,
  mail1: process.env.NEXT_PUBLIC_MAIL_1,
  mail2: process.env.NEXT_PUBLIC_MAIL_2,
  twilioAccountSID: process.env.TWILIO_ACCOUNT_SID,
  twilioAuthToken: process.env.TWILIO_AUTH_TOKEN,
  twilioServiceAccountSID: process.env.TWILIO_SERVICE_SID,
};

const awsConfig = {
  region: process.env.REGION,
  awsImageAssetBucket: process.env.NEXT_PUBLIC_AWS_IMAGE_ASSET_BUCKET,
  awsPrivateBucket: process.env.AWS_PRIVATE_ASSETS_BUCKET,
  awsPublicBucket: process.env.AWS_PUBLIC_ASSETS_BUCKET,
  awsAccessKey: process.env.AWS_ACCESS_KEY,
  awsSecretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
};

export { baseURL, dbUrl, appConfig, awsConfig, isProduction };
