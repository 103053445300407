"use client";

import React from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CallIcon from "@mui/icons-material/Call";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";

export default function InfoBar() {
  return (
    <Box bgcolor={"#000"} px={3}>
      <Box display="flex" alignItems="center" flexDirection="row">
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: "white",
          }}
        >
          <IconButton
            color="inherit"
            href="https://www.youtube.com/@BrainMentorsPvtLtd"
            aria-label="Youtube"
            sx={{ alignSelf: "center" }}
          >
            <YouTubeIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://in.linkedin.com/company/brain-mentors"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center" }}
          >
            <LinkedInIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.instagram.com/brain.mentors"
            aria-label="Instagram"
            sx={{ alignSelf: "center" }}
          >
            <InstagramIcon fontSize="small" />
          </IconButton>
        </Stack>
        <Box flexGrow={1} />
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: "white",
            cursor: "pointer",
          }}
        >
          <CallIcon fontSize="small" />
          <a
            href="tel:+7042994524"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Typography variant="body2">7042994524 or 7042434524</Typography>
          </a>
        </Stack>
      </Box>
    </Box>
  );
}
